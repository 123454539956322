// import request, {put} from '@/utils/request'
import {get,post,Del,put,GET} from '@/utils/request'

// export function getList(params) {
//   return request({
//     url: '/vue-admin-template/table/list',
//     method: 'get',
//     params
//   })
// }
export const getMap=(data)=>GET(`https://apis.map.qq.com/ws/geocoder/v1/?`,data); //banner下线


// 玩转度假区
export const getSightseeingList=(data)=>get(`system/sightseeing/listPc`,data); //度假区列表
export const getSightseeingInfo=(sightseeingId,data)=>get(`system/sightseeing/${sightseeingId}`,data); //度假区详情

// 度假区导览
export const getBannerowInfo=(data)=>get(`system/Bannerow/listPc`,data); //度假区导览列表

// 度假资源
export const getHistoryInfo=(historyId,data)=>get(`system/history/${historyId}`,data); //度假区导览列表

// 特色消费
export const getproduct=(data)=>get(`system/product/listPc`,data); //特色消费列表
export const getProductInfo=(productId,data)=>get(`system/product/${productId}`,data); //特色消费详情

